import React from 'react';

const Theses = ({ theses }) => (
	<section id="portfolio-theses" className="portfolio-section">
		<h3 className="portfolio-section-header">Theses</h3>
		<ol className="portfolio-section-content">
			{theses.map((thesis, thesis_index) => (
				<li key={thesis_index} className="portfolio-section-item">
					<h4 className="portfolio-section-item-header hide-element">{thesis.name}</h4>
					<dl className="portfolio-section-item-info">
						<div>
							<dt aria-hidden="true">Title</dt>
							<dd className="info-title" aira-hidden="true">{thesis.name}</dd>
							<dt>Date</dt>
							<dd className="info-date">{thesis.releaseDate}</dd>
						</div>
						<div>
							<dt>Authors</dt>
							<dd className="info-authors">{thesis.authors.join(", ")}</dd>
							<dt>Publisher</dt>
							<dd className="info-publisher">{thesis.publisher}</dd>
						</div>
					</dl>
				</li>
			))}
		</ol>
	</section>
);

export default Theses;
