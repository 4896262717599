import React from 'react';

const Profiles = ({ profiles }) => (
	<section id="portfolio-profiles" className="portfolio-section">
		<h3 className="portfolio-section-header">Profiles</h3>
		<dl className="portfolio-section-content">
			{profiles.map((profile, profile_index) => (
				<div key={profile_index} className="portfolio-section-item portfolio-section-item-info">
					<dt>{profile.network}</dt>
					<dd>
						{profile.username && (profile.url ? (
							<a href={profile.url}>{profile.username}</a>
						) : (
							profile.username
						))}
					</dd>
				</div>
			))}
		</dl>
	</section>
);

export default Profiles;
