import React from 'react';

const Contact = ({ basics }) => (
	<section id="portfolio-contact" className="portfolio-section">
		<h3 className="portfolio-section-header">Contact</h3>
		<dl className="portfolio-section-content">
			<div className="portfolio-section-item portfolio-section-item-info">
				<dt>Website</dt>
				<dd><a href={basics.url}>{basics.url}</a></dd>
			</div>
			<div className="portfolio-section-item portfolio-section-item-info">
				<dt>Email</dt>
				<dd>{basics.email}</dd>
			</div>
			<div className="portfolio-section-item portfolio-section-item-info">
				<dt>Location</dt>
				<dd>{basics.location.region}</dd>
			</div>
		</dl>
	</section>
);

export default Contact;
