import React from 'react';
import { Helmet } from 'react-helmet';
import Header from './header';
import Navigation from './nav';
import Contact from './contact';
import About from './about';
import Profiles from './profiles';
import Work from './work';
// import Volunteer from './volunteer';
import Education from './education';
import Awards from './awards';
// import Publications from './publications';
import Theses from './theses';
import Presentations from './presentations';
import Skills from './skills';
import Footer from './footer';
// import Languages from './languages';
// import Interests from './interests';
// import References from './references';

import Favicon from '../images/favicon.ico';

const Resume = ({ resume }) => {
	const { basics, work, education, awards, theses, presentations, skills } = resume;
	return (
		<React.Fragment>
			<Helmet>
				<title>Resume &mdash; {basics.name}</title>
				<meta name="description" content={`resume for ${basics.name}`} />
				<link rel="shortcut icon" href={`${Favicon}`} />
			</Helmet>
			<Header basics={basics} />
			<Navigation basics={basics} />
			<main id="portfolio-main">
				<Contact basics={basics} />
				<About basics={basics} />
				<Profiles profiles={basics.profiles||[]} />
				<Work works={work} />
				<Education educations={education} />
				<Awards awards={awards} />
				<Theses theses={theses} />
				<Presentations presentations={presentations} />
				<Skills skills={skills} />
			</main>
			<Footer basics={basics} />
		</React.Fragment>
	);
};

export default Resume;
