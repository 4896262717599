import React from 'react';

const Awards = ({ awards }) => (
	<section id="portfolio-awards" className="portfolio-section">
		<h3 className="portfolio-section-header">Awards</h3>
		<ol className="portfolio-section-content">
			{awards.map((award, award_index) => (
				<li key={award_index} className="portfolio-section-item">
					<h4 className="portfolio-section-item-header hide-element">{award.title}</h4>
					<dl className="portfolio-section-item-info">
						<div>
							<dt aria-hidden="true">Award</dt>
							<dd className="info-award" aira-hidden="true">{award.title}</dd>
						</div>
						<div>
							<dt>Awarder</dt>
							<dd className="info-awarder">{award.awarder}</dd>
							<dt>Date</dt>
							<dd className="info-date">{award.date}</dd>
						</div>
					</dl>
					{award.summary && (
						<div className="portfolio-section-item-summary">
							<p>{award.summary}</p>
						</div>
					)}
				</li>
			))}
		</ol>
	</section>
);

export default Awards;
