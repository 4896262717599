import React from 'react';

const Education = ({ educations }) => (
	<section id="portfolio-education" className="portfolio-section">
		<h3 className="portfolio-section-header">Education</h3>
		<ol className="portfolio-section-content">
			{educations.map((education, education_index) => (
				<li key={education_index} className="portfolio-section-item">
					<h4 className="portfolio-section-item-header hide-element">{education.institution}</h4>
					<dl className="portfolio-section-item-info">
						<div>
							<dt aria-hidden="true">Institution</dt>
							<dd className="info-institution" aira-hidden="true">{education.institution}</dd>
							<dt>Website</dt>
							<dd className="info-website"><a href={education.url}>{education.url}</a></dd>
						</div>
						<div>
							<dt>Study</dt>
							<dd className="info-study"><span className="info-study-degree">{education.studyType}</span> — <span className="info-study-area">{education.area}</span></dd>
							<dt>Date</dt>
							<dd className="info-date">{education.startDate} — {education.endDate||"Current"}</dd>
						</div>
					</dl>
				</li>
			))}
		</ol>
	</section>
);

export default Education;
