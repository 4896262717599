import React from 'react';

const Header = ({ basics }) => (
	<header id="portfolio-header">
		<h1>{basics.name}</h1>
		<h2>{basics.label}</h2>
	</header>
);

export default Header;
