import React from 'react';

const Presentations = ({ presentations }) => (
	<section id="portfolio-presentations" className="portfolio-section">
		<h3 className="portfolio-section-header">Presentations</h3>
		<ol className="portfolio-section-content">
			{presentations.map((presentation, presentation_index) => (
				<li key={presentation_index} className="portfolio-section-item">
					<h4 className="portfolio-section-item-header hide-element">{presentation.name}</h4>
					<dl className="portfolio-section-item-info">
						<div>
							<dt aria-hidden="true">Title</dt>
							<dd className="info-title" aira-hidden="true">{presentation.name}</dd>
							<dt>Date</dt>
							<dd className="info-date">{presentation.startDate} — {presentation.endDate||"Current"}</dd>
						</div>
						<div>
							<dt>Event</dt>
							<dd className="info-event">{presentation.event}</dd>
							<dt>Location</dt>
							<dd className="info-location">{presentation.location}</dd>
						</div>
						<div>
							<dt>Presentors</dt>
							<dd className="info-presentors">{presentation.authors.join(", ")}</dd>
						</div>
					</dl>
					{presentation.summary && (
						<div className="portfolio-item-summary">
							<p>{presentation.summary}</p>
						</div>
					)}
				</li>
			))}
		</ol>
	</section>
);

export default Presentations;
