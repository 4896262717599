import React from 'react';
import { graphql } from 'gatsby';

import Resume from '../components/resume';
import '../../node_modules/sanitize.css/sanitize.css'
import '../../node_modules/sanitize.css/typography.css'
import '../styles/style.css'

const Page = ({ data }) => {
  const resume = data.resumeYaml;
  return <Resume resume={resume} />;
};

export default Page;

export const query = graphql`
  query MyQuery {
    resumeYaml {
      basics {
        lastUpdated(formatString: "MMMM, YYYY")
        name
        label
        email
        location {
          region
          countryCode
        }
        url
        summary
        profiles {
          network
          url
          username
        }
      }
      work {
        description
        endDate(formatString: "MMM, YYYY")
        highlights
        location
        name
        startDate(formatString: "MMM, YYYY")
        position
        summary
        url
      }
      education {
        institution
        area
        endDate(formatString: "MMM, YYYY")
        startDate(formatString: "MMM, YYYY")
        studyType
        url
      }
      awards {
        awarder
        date(formatString: "MMM, YYYY")
        summary
        title
      }
      theses {
        name
        authors
        publisher
        releaseDate(formatString: "MMM, YYYY")
        summary
      }
      presentations {
        name
        authors
        event
        location
        endDate(formatString: "MMM DD, YYYY")
        startDate(formatString: "MMM DD, YYYY")
        summary
      }
      skills {
        keywords
        name
      }
    }
  }
`;
