import React from 'react';

const Work = ({ works }) => (
	<section id="portfolio-works" className="portfolio-section">
		<h3 className="portfolio-section-header">Work</h3>
		<ol className="portfolio-section-content">
			{works.map((work, work_index) => (
				<li key={work_index} className="portfolio-section-item">
					<h4 className="portfolio-section-item-header hide-element">{work.name}</h4>
					<dl className="portfolio-section-item-info">
						<div>
							<dt aria-hidden="true">Employer</dt>
							<dd className="info-employer" aira-hidden="true">{work.name}</dd>
							<dt>Website</dt>
							<dd className="info-website"><a href={work.url}>{work.url}</a></dd>
						</div>
						<div>
							<dt>Position</dt>
							<dd className="info-position">{work.position}</dd>
							<dt>Date</dt>
							<dd className="info-date">{work.startDate} — {work.endDate||"Current"}</dd>
						</div>
					</dl>
					<div className="portfolio-section-item-summary">
						<p>{work.summary}</p>
						{work.highlights && (
							<React.Fragment>
								<h5 className="summary-highlights-header">Highlights</h5>
								<ul className="summary-highlights">
									{work.highlights.map((highlight, highlight_index) => (
										<li key={highlight_index}>{highlight}</li>
									))}
								</ul>
							</React.Fragment>
						)}
					</div>
				</li>
			))}
		</ol>
	</section>
);

export default Work;
