import React from 'react';

const About = ({ basics }) => (
	<section id="portfolio-about" className="portfolio-section">
		<h3 className="portfolio-section-header">About</h3>
		<p className="portfolio-section-content portfolio-section-item portfolio-section-item-summary">{basics.summary}</p>
	</section>
);

export default About;
