import React from 'react';

const Skills = ({ skills }) => (
	<section id="portfolio-skills" className="portfolio-section">
		<h3 className="portfolio-section-header">Skills</h3>
		<dl className="portfolio-section-content">
			{skills.map((skill, skill_index) => (
				<div key={skill_index} className="portfolio-section-item portfolio-section-item-info">
					<dt>{skill.name}</dt>
					<dd>
						<ul>
						{skill.keywords.map((keyword, keyword_index) => (
							<li key={keyword_index}>{keyword}</li>
						))}
						</ul>
					</dd>
				</div>
			))}
		</dl>
	</section>
);

export default Skills;
