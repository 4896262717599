import React from 'react';
// import { useStaticQuery, graphql } from "gatsby";
// import resumepdf from '../../resume/resume.pdf';

const Navigation = ({ basics }) => {
	// const data = useStaticQuery(graphql`
	// 	{
	// 		file(relativePath: {eq: "resume.pdf"}) {
	// 			publicURL
	// 			name
	// 		}
	// 	}
	// `)
	
	return (
		// Need React.Fragment to wrap potentially empty result
			// <li><a href={data.file.publicURL}>Download Resume</a></li>
		<React.Fragment>
			{(
				<nav id="portfolio-navigation">
					<ol>
						<li><a href={'/resume_stwatson.pdf'}>Download Resume</a></li>
					</ol>
				</nav>
			)}
		</React.Fragment>
	)
	
	// Uncomment below for finding multiple files instead
	/*const data = useStaticQuery(graphql`
		{
			allFile(
				limit: 1,
				filter: { extension: { eq: "pdf" } }
			) {
				edges {
					node {
						publicURL
						name
					}
				}
			}
		}
	`)
	
	return (
		// Need React.Fragment to wrap potentially empty result
		<React.Fragment>
			{data.allFile.edges.length > 0 && (
				<nav id="portfolio-navigation">
					<ol>
						{data.allFile.edges.map(file => (
							<li><a href={file.node.publicURL}>Download Resume</a></li>
						))}
					</ol>
				</nav>
			)}
		</React.Fragment>
	)*/
};

export default Navigation;
